import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Card,
  WixDesignSystemProvider,
  Text,
  Page,
  Loader,
  TableActionCell,
  Table,
  TableToolbar,
  Button,
  FormField,
  Input,
  Badge,
  Tooltip,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [plans, setPlans] = React.useState([] as any);
  const [currentPlanId, setCurrentPlanId] = React.useState("");
  const [paymentMethodId, setPaymentMethodId] = React.useState("ecpay");

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const baseUrl = "https://certifiedcode.wixsite.com/wallet/_functions";

  const currentPlan = plans.find((plan: any) => plan.id === currentPlanId);

  const currentConfig = currentPlan?.config?.config || {};

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/wallet/_functions", // Replace with your API base URL
      applicationID: "16690ff2-2fd8-44de-be90-42b123431c6f", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      
    </WixDesignSystemProvider>
  );
}

export default App;
